<template>
  <div class="login">
    <!-- 登录区域 -->
    <div class="login_content">
      <!-- 登录区域--左边 -->
      <div class="content_left">
        <img src="../../assets/image/login_slices/group_274.png" alt="">
      </div>
      <!-- 登录区域--右边 -->
      <div class="content_right">
        <!-- 输入框区域 -->
        <div class="content_ipt">
          <h1>欢迎登录</h1>
          <!-- 输入框 -->
          <div class="ipt">
            <p style="margin-top: 4.8vh;">手机号</p>
            <input type="text" style="margin-top: 2vh;" v-model="form.phone" maxlength="11">
            <p style="margin-top: 3vh;">验证码</p>
            <input type="text" style="margin-top: 3vh;" v-model="form.code" maxlength="35">
            <!-- <p style="font-size: 2.2vh; cursor: pointer;">密码验证</p> -->
            <el-link :underline="false" style="cursor: pointer;" @click="getCode()" class="code"
              :disabled="isDisable">获取验证码</el-link>
            <button @click="userLogin" style="cursor: pointer;" @keyup.enter="userLogin">登录</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import { msg } from "../../common/public";
// 引入登录的api接口
import { loginCode, login } from "../../utils/api";


export default {
  data () {
    return {
      form: {
        phone: '',
        code: ''
      },
      timer: null,//定时器
      isDisable: false,//是否禁止
      loading: null,
    };
  },

  mounted () {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown)
  },
  destroyed () {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    // 获取验证码
    getCode () {
      let phoneReg = /^1[3|5|7|8|9]\d{9}$/;
      if (this.isDisable) {
        return false
      }
      // 检验数据 手机号是否为空
      if (!this.form.phone) {
        Message.error('手机号不能为空');
        return false
      } else if (!phoneReg.test(this.form.phone)) {
        Message.error('手机号格式不正确，请重新输入');
        return false
      } else {
        // 请求获取验证码数据
        loginCode({phone:  this.form.phone}).then(res => {
          if (res.code == 1) {
            Message.success(res.msg)
          }
        }).catch(err => {
          console.log(err);
        })
      }
      this.isDisable = true
      let codeDom = document.querySelector('.code');
      let n = 60;
      this.timer = setInterval(() => {
        --n;
        codeDom.innerHTML = n + '秒后重发';
        codeDom.style.color = "#171717";
        if (n < 0) {
          n = 0;
          codeDom.innerHTML = '获取验证码';
          codeDom.style.color = "#0666f5";
          clearInterval(this.timer);
          this.isDisable = false
        }
      }, 1000)
    },
    //登陆
    userLogin () {
      login(this.form).then(res => {
        if (res.code == 1) {
          this.loading = this.$loading({
            lock: true,
            text: '登录中....',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          console.log(res.data)
          //将获取的token存入cookie
          let expdate = new Date();
          expdate.setTime(expdate.getTime() + 7 * 24 * 60 * 60 * 1000); //设置cookie时间 
          let userInform = res.data.token
          document.cookie = `userInform=${userInform}` + ";expires=" + expdate.toGMTString() + ";path=/";

          // 先将个人数据存入session
          let user = res.data.data
          console.log(user)
          user = JSON.stringify(user)
          let timer = setTimeout(() => {
            Message.success('登录成功');
            this.$router.push('/');
            localStorage.setItem('user', user);
            clearTimeout(timer)
            this.loading.close()
          }, 1000)
        } else {
          this.loading.close()
        }
      }).catch(err => {
        this.loading.close()
        return console.log(err);
      })
    },
    keyDown (e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.userLogin(); // 定义的登录方法
      }
    },


  },
};
</script>

<style scoped>
/* 登录区域的样式 */
.login {
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  background-image: url("../../assets/image/login_slices/backgroundImage.png");
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 800px;
}
/* 登录区域内部样式--左边图片 */
.login_content {
  width: 63.4%;
  height: 67.9vh;
  background-color: #fff;
  display: flex;
  min-width: 800px;
  max-width: 1200px;
}
.login_content .content_left {
  width: 100%;
  width: 540px;
  height: 100%;
}
/* 左侧图片样式 */
.login_content .content_left img {
  width: 100%;
  height: 101%;
}
/* 登录区域内部样式 -- 右边输入框样式 */
.login_content .content_right {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 输入框区域 */
.content_right .content_ipt {
  width: 90%;
  height: 46.5vh;
}
/* 欢迎登陆样式 */
.content_ipt h1 {
  font-size: 37px;
  font-family: "SHSCN_Bold";
  color: #171717;
  font-weight: bold;
}
/* 输入框 */
.content_right .content_ipt input {
  width: 90%;
  height: 5.18vh;
  background-color: #efefef;
  font-size: 2vh;
  padding-left: 1.28vw;
}
/* 手机号，验证码的样式 */
.content_right .content_ipt p {
  font-size: 20px;
  font-family: "SHSCN_Regular";
  color: #171717;
}
/* 密码登录 */
.content_right .content_ipt p:nth-child(5) {
  margin-top: 2.3vh;
  font-size: 2.2vh;
  color: #707070;
  font-family: "SHSCN_Regular";
}
/* 获取验证码 */
.content_right .code {
  color: #0666f5;
  font-size: 2.2vh;
  font-family: "SHSCN_Regular";
  position: relative;
  left: -10%;
  cursor: pointer;
  float: right;
  margin-top: -8vh;
  z-index: 999;
}
/* 登录按钮 */
.content_right .content_ipt button {
  width: 90%;
  height: 7vh;
  background-color: #0666f5;
  border-radius: 3.5vh 3.5vh 3.5vh 3.5vh;
  margin-top: 5.6vh;
  color: #fff;
  font-size: 2.77vh;
}
</style>